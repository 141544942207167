#wrapper { 
    position: relative;  
    height: 100vh;
    overflow-x: hidden;
}

@include media-breakpoint-up(lg) {
    //
    // Header
    //
    .header-fixed {
        .app-header {
            position: fixed;
            z-index: $zindex-sticky;
            width: inherit;
        }
        .app-body {
            margin-top: 0;
        }
    }
    //
    // Sidebar
    //
    // .sidebar-fixed {
    //     .sidebar {
    //         // margin-top: $navbar-height;
    //         margin-top: 0;
    //     }
    // }
}
.user-nav {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0.5rem 0.5rem;
    margin-top: 0.6rem;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    background: rgba(0,0,0,0.2);
    transition: 0.5s;
    opacity: 0.7;
    z-index: 1030;
}
@include media-breakpoint-up(lg) {
    .user-nav {
        margin-right: -1.5rem;
    }
    .user-nav:hover, .user-nav:active {
        margin-right: 0;
    }
}
.user-nav:hover, .user-nav:active {
    opacity: 1;
    background: rgba(0,0,0,0.4);
}
.user-nav .nav-link {
    padding:0;
}
.app-header .nav-item .nav-link > .img-avatar, .app-header .nav-item .avatar.nav-link > img {
    height: 30px;
    margin: 0 5px;
}
.sidebar-fixed .app-header + .app-body .sidebar {
    height: calc(100vh); 
}